import React from "react";
import { useSetDanceLevelPreferenceMutation } from "services/graphql";
import { trackSelectDanceLevelEventAction } from "modules/onboarding";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { incrementStep } from "app/routes/Register/helpers";
import { Option } from "../../Option";
import { LevelsWrapper } from "./LevelsWrapper";
import { useAnimate } from "../useAnimate";
import { FROM_MODULE } from "../../constants";

interface Props {
  levels?: Level[];
  isClickDisabled: boolean;
  toggleIsClickDisabled(isClickDisabled: boolean): void;
}

interface Level {
  description: string;
  label: string;
  previewUrl: string;
  slug: string;
}

export function Levels({
  levels = [],
  isClickDisabled,
  toggleIsClickDisabled,
}: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { animationControls, animate } = useAnimate();

  const [setDanceLevel] = useSetDanceLevelPreferenceMutation({
    onCompleted: ({ setDanceLevelPreference: setLevelResult }) => {
      const { slug } = setLevelResult;

      dispatch(
        trackSelectDanceLevelEventAction({
          danceLevel: slug,
          module: FROM_MODULE,
        })
      );

      incrementStep({ stepsToIncrement: 1, location, history });
      toggleIsClickDisabled(false);
    },
  });

  return (
    <LevelsWrapper animationControls={animationControls}>
      {levels &&
        levels.map((level: Level) => (
          <Option
            key={level.slug}
            onSelect={() => {
              if (isClickDisabled) {
                return;
              }

              toggleIsClickDisabled(true);

              setTimeout(animate, 200);

              setDanceLevel({
                variables: {
                  slug: level.slug,
                },
              });
            }}
            label={level.label}
            slug={level.slug}
            description={level.description}
          />
        ))}
    </LevelsWrapper>
  );
}
